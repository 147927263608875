import clsx from 'clsx'
import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../../app/modules/auth'
import {BatchesStatus} from '../../../../app/modules/batches/core/_const'
import {OrderStatus} from '../../../../app/modules/orders/core/_const'
import {KTSVG, allowTestOnly, checkIsActive, checkIsActiveSubRoute} from '../../../helpers'
import UseRoutesPermission from '../../../hooks/UseRoutesPermission'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {useAsideProvider} from '../../../providers/AsideProvider'
import {first, isArray} from 'lodash'

const MenuItem = ({item, idx = null, isChild = false, className = ''}) => {
  const navigate = useNavigate()
  const {isMobileTablet, isWindows} = useWindowDimensions()
  const {activeFooter, setActiveFooter} = useAsideProvider()
  const {pathname} = useLocation()
  const {hasShow, to, icon, title, children} = item
  const isActive =
    (typeof to === 'string' ? checkIsActive(pathname, to) : checkIsActiveSubRoute(to, pathname)) &&
    !activeFooter

  const handleItemClick = () => {
    if (children) {
    } else {
      const path = isArray(to) ? first(to) : to
      navigate(path)
    }
    setActiveFooter(null)
  }

  if (!hasShow) {
    return null
  }

  return (
    <div
      key={idx}
      className={clsx(`${className}__center__item`, {
        active: isActive,
        child: isChild,
      })}
    >
      <div className='mb-2' onClick={handleItemClick}>
        <div
          className={clsx('accordion-header d-flex align-items-center', {
            child: isChild,
            'mx-2': isChild && isMobileTablet,
            'bg-primary rounded-2 py-1': isChild && isActive && isMobileTablet,
            collapsed: !isActive,
          })}
          data-bs-toggle='collapse'
          data-bs-target={`#kt_accordion_menu_main_item_${idx}`}
          data-kt-menu-trigger='click'
        >
          {isChild ? (
            <KTSVG
              path='/media/gori/arrows/arrow_right.svg'
              className={`${className}__center__item__icon  px-4 child`}
            />
          ) : (
            <KTSVG
              path={icon}
              small={false}
              className={`${className}__center__item__icon`}
              svgClassName={(isMobileTablet && 'mh-40px') || (isWindows && 'mh-50px') || ''}
            />
          )}
          <span
            className={clsx(`flex-fill fs-5 fs-xxl-6 ${className}__center__item__title`, {
              'fw-bolder': !isActive,
              'fw-boldest': isActive,
              child: isChild,
            })}
          >
            {title}
          </span>
          {children && (
            <KTSVG
              path={'/media/gori/arrows/arrow_right.svg'}
              className={clsx('accordion-icon me-2', {active: isActive})}
            />
          )}
        </div>
        {children && (
          <div
            id={`kt_accordion_menu_main_item_${idx}`}
            className={clsx('accordion-body fs-6 collapse pb-1', {show: isActive})}
            data-bs-parent={`#kt_accordion_menu_main_${className}`}
          >
            {children.map((childItem) => (
              <MenuItem item={childItem} isChild className={className} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const Menu = ({configMenu, className = ''}) => {
  const {setHasShowSubmenu, activeFooter} = useAsideProvider()

  const handleClickMenu = () => {
    if (!activeFooter) return
    setHasShowSubmenu(true)
  }

  return (
    <div
      className='accordion accordion-icon-toggle'
      id={`kt_accordion_menu_main_${className}`}
      onClick={handleClickMenu}
    >
      {configMenu.map((menuItem, idx) => (
        <MenuItem idx={idx} item={menuItem} className={className} />
      ))}
    </div>
  )
}

type Props = {
  className: string
}

const AsideMenuLayout: React.FC<Props> = ({className = ''}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {routes} = UseRoutesPermission()

  const configMenu = [
    {
      hasShow: currentUser && routes.DASHBOARD.hasPermission,
      to: '/dashboard',
      icon: '/media/gori/aside/dashboard.svg',
      title: intl.formatMessage({id: 'DASHBOARD'}),
    },
    {
      hasShow:
        currentUser && (routes.ORDERS_VIEW.hasPermission || routes.BATCHES_VIEW.hasPermission),
      to: ['/orders', '/batches'],
      icon: '/media/gori/aside/orders.svg',
      title: intl.formatMessage({id: 'ORDERS'}),
      children: [
        {
          hasShow: routes.ORDERS_VIEW.hasPermission,
          to: `/orders?status=` + OrderStatus?.OPEN,
          icon: '/media/gori/aside/orders.svg',
          title: intl.formatMessage({id: 'ORDERS'}),
        },
        {
          hasShow: routes.BATCHES_VIEW.hasPermission,
          to: `/batches?status=` + BatchesStatus?.OPEN,
          icon: '/media/gori/aside/orders.svg',
          title: intl.formatMessage({id: 'BATCHES'}),
        },
      ],
    },
    {
      hasShow:
        currentUser && (routes.SHIPMENTS_VIEW.hasPermission || routes.CLAIMS_VIEW.hasPermission),
      to: ['/shipments', '/claims', '/open-shipments', '/closed-shipments'],
      icon: '/media/gori/aside/shipments.svg',
      title: intl.formatMessage({id: 'SHIPMENTS'}),
      children: [
        {
          hasShow: routes.SHIPMENTS_VIEW.hasPermission,
          to: '/shipments',
          icon: '/media/gori/aside/shipments.svg',
          title: intl.formatMessage({id: 'SHIPMENTS'}),
        },
        {
          hasShow: routes.CLAIMS_VIEW.hasPermission,
          to: '/claims',
          icon: '/media/gori/aside/claims.svg',
          title: intl.formatMessage({id: 'CLAIMS'}),
        },
        {
          hasShow: routes.SHIPMENTS_VIEW.hasPermission,
          to: ['/open-shipments', '/closed-shipments'],
          icon: '/media/gori/aside/shipments.svg',
          title: intl.formatMessage({id: 'END_OF_DAY'}),
        },
      ],
    },
    {
      hasShow:
        currentUser &&
        (routes.REPORTS_SHIPMENTS.hasPermission || routes.REPORTS_TRANSACTIONS.hasPermission),
      to: '/reports',
      icon: '/media/gori/aside/logs.svg',
      title: intl.formatMessage({id: 'REPORTS'}),
    },
    {
      hasShow: currentUser && allowTestOnly(currentUser),
      to: ['/baewatch'],
      icon: '/media/gori/aside/baewatch.svg',
      title: intl.formatMessage({id: 'WARRANTY'}),
      children: [
        {
          hasShow: true,
          to: '/baewatch',
          icon: '/media/gori/aside/baewatch.svg',
          title: intl.formatMessage({id: 'BAEWATCH'}),
        },
      ],
    },
    {
      hasShow: currentUser && routes.DEPOSITS.hasPermission,
      to: '/deposits',
      icon: '/media/gori/aside/deposit.svg',
      title: intl.formatMessage({id: 'DEPOSITS'}),
    },
    {
      hasShow: currentUser && routes.SYSTEM_ADMIN.hasPermission,
      to: '/admin',
      icon: '/media/gori/aside/admin.svg',
      title: intl.formatMessage({id: 'ADMIN'}),
    },
  ]

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <Menu configMenu={configMenu} className={className} />
    </CSSTransition>
  )
}

export {AsideMenuLayout}
