import clsx from 'clsx'
import {cloneDeep, isEmpty, isEqual, mapValues, omit} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {DropdownButton} from '../../../../../_gori/partials/widgets'
import {SettingsConfig} from '../../../settings/core/_const'
import SettingsService from '../../../settings/core/_requests'
import {OrdersConfig} from '../../core/_const'

type Props = {
  formik: any
  disabled: boolean
  fromCreateOrder: boolean
  dataCopy: any
  setDataRates: any
  renderFirst: any
}

const PresetApplyButton: React.FC<Props> = ({
  formik,
  disabled = false,
  fromCreateOrder,
  dataCopy,
  setDataRates,
  renderFirst,
}) => {
  const intl = useIntl()
  const params = useParams()
  const navigate = useNavigate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [dataPresetDefault, setDataPresetDefault] = useState([])
  const {statesGlobal, getPresets} = useStatesGlobal()
  const presets = statesGlobal.presets

  // BEGIN: Action Apply Preset
  const getPresetDefault = useCallback(async () => {
    try {
      const {default_preset} = await SettingsService.getDefaultPreset({
        cancelToken: newCancelToken(),
      })
      setDataPresetDefault(default_preset)
    } catch (error) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken, setDataPresetDefault])

  const optionsPreset = useMemo(() => {
    const handleApplyPreset = async (item) => {
      formik.setErrors({})

      const applyAddress = (name) => {
        const result: any = Object.entries(OrdersConfig.SHIPPING).reduce(
          (initValue, [key, value]) => {
            switch (value.value) {
              case OrdersConfig.SHIPPING.RESIDENTIAL.value:
                initValue[`${name}_${value.value}`] =
                  item?.shipping_options?.[`${name}`]?.[`${name}_${value.value}`] || false
                break
              case OrdersConfig.SHIPPING.VERIFIED.value:
              case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
                break
              default:
                initValue[`${name}_${value.value}`] =
                  item?.shipping_options?.[`${name}`]?.[`${name}_${value.value}`] || ''
                break
            }
            return initValue
          },
          {}
        )
        formik.setFieldValue(name, result)
      }

      const applyService = () => {
        const service = item?.shipping_options?.service ?? null
        if (service) {
          setDataRates([])
          formik.setFieldValue('service', service)
        }
      }
      const applyParcel = () => {
        const initParcel = {
          package_id: null,
          package_type: '',
          length: '',
          width: '',
          height: '',
          weight: '',
          dimension_unit: 'in',
          weight_unit: 'oz',
        }
        const parcel = Object.entries(item?.shipping_options?.parcels?.[0])?.reduce(
          (_parcel, [key, value]) => {
            if (value) {
              _parcel[key] = value
            }
            return _parcel
          },
          initParcel
        )
        formik.setFieldValue('parcels.0', parcel)
      }

      await applyAddress('from')
      await applyAddress('to')
      await applyService()
      await applyParcel()
    }
    const optionPresetApi = !isEmpty(presets)
      ? presets.map((item: any) => ({
          label: item?.name,
          value: item.id,
          action: () => handleApplyPreset(item),
          className: 'cursor-pointer',
        }))
      : []

    if (!params?.id && renderFirst.current && !isEmpty(dataPresetDefault) && !dataCopy) {
      dataPresetDefault.forEach((item: any) => {
        if (item.order_type === SettingsConfig.DEFAULT_PRESET.SHIPBAE) {
          handleApplyPreset(item.preset)
        }
      })
      renderFirst.current = false
    }

    return [
      ...optionPresetApi,
      {
        label: <span className='text-primary'>{intl.formatMessage({id: 'MANAGE_PRESET'})}</span>,
        action: () => {
          navigate('/settings/presets')
        },
        className: 'cursor-pointer bg-hover-light-primary',
      },
    ]
  }, [
    presets,
    params?.id,
    renderFirst,
    dataPresetDefault,
    dataCopy,
    intl,
    formik,
    setDataRates,
    navigate,
  ])

  useEffect(() => {
    if (fromCreateOrder) return
    getPresets()
    if (!params?.id && isEmpty(dataCopy)) {
      getPresetDefault()
    }

    return () => {}
  }, [fromCreateOrder, getPresetDefault, getPresets, params?.id, dataCopy])
  // END: Action Apply Preset

  // BEGIN: Set Preset Id for order
  const convertString = (inputObject) => {
    return mapValues(inputObject, (value) => (typeof value === 'number' ? value.toString() : value))
  }

  useEffect(() => {
    if (isEmpty(presets)) return
    const foundPreset = presets?.filter((preset) => {
      const initParcel = {
        package_id: null,
        package_type: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        dimension_unit: 'in',
        weight_unit: 'oz',
      }
      const _presetParcel = cloneDeep({...initParcel, ...preset?.shipping_options?.parcels?.[0]})
      const checkEqualParcels = isEqual(
        convertString(omit(cloneDeep(formik.values.parcels[0]), ['weight_limit'])),
        convertString(omit(cloneDeep(_presetParcel), ['weight_limit']))
      )
      const checkEqualFrom = isEqual(
        omit(cloneDeep(formik.values.from), ['from_is_verified', 'from_type']),
        omit(cloneDeep(preset?.shipping_options?.from), ['from_is_verified', 'from_type'])
      )
      const checkEqualTo = isEqual(
        omit(cloneDeep(formik.values.to), ['to_is_verified', 'to_type']),
        omit(cloneDeep(preset?.shipping_options?.to), ['to_is_verified', 'to_type'])
      )
      const checkEqualService = isEqual(formik.values.service, preset?.shipping_options?.service)
      if (checkEqualParcels && checkEqualFrom && checkEqualTo && checkEqualService) {
        return preset
      }
      return null
    })

    formik.setFieldValue('preset_id', foundPreset?.[0]?.id || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.parcels, formik.values.service, formik.values.from, formik.values.to])
  // END: Set Preset Id for order

  return (
    <>
      <div
        className={clsx('text-muted text-hover-primary fw-bolder', {
          'cursor-no-drop': disabled,
        })}
      >
        <DropdownButton
          className={clsx('bg-hover-light-primary p-2 rounded-2', {
            'pe-none': disabled,
          })}
          title={intl.formatMessage({id: 'APPLY_PRESET'})}
          icon={<KTSVG path='/media/gori/arrows/arrow_down.svg' className='svg-icon-2' />}
          value={formik.values?.preset_id}
          list={optionsPreset}
        />
      </div>
    </>
  )
}

export {PresetApplyButton}
