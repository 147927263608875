import {StorageHelpers} from '../../../../_gori/helpers'
import AuthRequestBase from '../../../api/AuthRequestBase'
import {AxiosRequestConfig} from 'axios'

const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const BASE_URL = `users`

function getUser(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${BASE_URL}`, config)
}

function getMeter() {
  const currentCompany = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  return AuthRequestBase.get(
    `/${BASE_URL}/meter${currentCompany ? `?selected=${currentCompany}` : ''}`
  )
}

function getListUser(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${BASE_URL}/list`, config)
}

function getListUserGlobal(config?: AxiosRequestConfig | undefined) {
  const currentCompany = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  return AuthRequestBase.get(`/${BASE_URL}/list?selected=${currentCompany}`, config)
}

function showUser(userId?: any, config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/${BASE_URL}/show/${userId}`, config)
}

function getPermissions(config?: AxiosRequestConfig | undefined) {
  return AuthRequestBase.get(`/permissions`, config)
}

const update = (userId?: any, payload?: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.put(`/${BASE_URL}/update/${userId}`, payload, config)
}

const updateHazmat = (id, payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.put(`/${BASE_URL}/hazmat/${id}`, payload, config)
}

const invite = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post(`/${BASE_URL}/invite`, payload, config)
}

function removeFromCompany(userId) {
  return AuthRequestBase.remove(`/${BASE_URL}/remove-from-company/${userId}`)
}

const UserService = {
  getUser,
  getMeter,
  getListUser,
  getPermissions,
  showUser,
  update,
  updateHazmat,
  getListUserGlobal,
  invite,
  removeFromCompany,
}

export default UserService
