import {isEmpty} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import {Button, ConfirmActionSwal, TableHeader} from '../../../../../../_gori/partials/widgets'
import {CreatePresetModal, DefaultPresetModal} from '../../../../settings'
import SettingsService from '../../../core/_requests'

type Props = {
  reloadTable: any
  selectedListId?: any
  dataPresetDefault?: any
}

const PresetFilter: React.FC<Props> = ({selectedListId, dataPresetDefault, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [showModal, setShowModal] = useState<{
    addPreset: boolean
    confirmDelete: boolean
    setAsDefault: boolean
  }>({
    addPreset: false,
    confirmDelete: false,
    setAsDefault: false,
  })

  const handleDelete = useCallback(async () => {
    if (isEmpty(selectedListId)) return
    setLoadingRemove(true)
    const payload = {
      preset_ids: selectedListId.map((item) => item.original.id),
    }

    try {
      const {message} = await SettingsService.deleteShippingPreset(payload, {
        cancelToken: newCancelToken(),
      })
      if (message) {
        reloadTable()
        toast.success(intl.formatMessage({id: message}))
      }
    } catch (error) {
      if (isCancel(error)) return
      toast.error(intl.formatMessage({id: 'DELETED_FAILED'}))
    } finally {
      setLoadingRemove(false)
    }
  }, [intl, isCancel, newCancelToken, reloadTable, selectedListId])

  return (
    <>
      {showModal.confirmDelete && (
        <ConfirmActionSwal
          show={showModal.confirmDelete}
          title={intl.formatMessage({id: 'DELETE_PRESET'})}
          message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
          messageCancel={intl.formatMessage({id: 'NO'})}
          handleCallBack={handleDelete}
          handleClose={() => setShowModal((prev) => ({...prev, confirmDelete: false}))}
        />
      )}

      {showModal.setAsDefault && (
        <DefaultPresetModal
          show={showModal.setAsDefault}
          handleClose={() => {
            setShowModal((prev) => ({...prev, setAsDefault: false}))
          }}
          reloadTable={reloadTable}
          dataPresetDefault={dataPresetDefault}
        />
      )}

      {showModal.addPreset && (
        <CreatePresetModal
          show={showModal.addPreset}
          handleClose={() => setShowModal((prev) => ({...prev, addPreset: false}))}
          reloadTable={reloadTable}
        />
      )}

      <TableHeader className='card-title d-flex flex-wrap gap-2 justify-content-between align-items-start p-0'>
        <div className='card-toolbar flex-row-fluid justify-content-end align-items-start'>
          <div
            className={`d-flex justify-content-end align-items-center ${
              !(selectedListId.length > 0) && 'd-none'
            }`}
          >
            <div className='fw-bolder me-5'>
              <span className='me-2'>{selectedListId.length}</span>
              {intl.formatMessage({id: 'SELECTED'})}
            </div>
            <Button
              className='btn bg-light-danger bg-hover-danger text-danger text-hover-white btn-sm fs-5 me-3'
              label={intl.formatMessage({id: 'REMOVE'})}
              loadingText={intl.formatMessage({id: 'REMOVE'})}
              event={() => setShowModal((prev) => ({...prev, confirmDelete: true}))}
              loading={loadingRemove}
            />
          </div>
          <div
            className='btn btn-light-primary btn-sm fs-5 me-3'
            onClick={() => {
              setShowModal((prev) => ({...prev, setAsDefault: true}))
            }}
          >
            {intl.formatMessage({id: 'DEFAULT_PRESET'})}
          </div>
          <div
            className='btn text-white text-hover-primary bg-primary bg-hover-light-primary btn-sm fs-5'
            onClick={() => {
              setShowModal((prev) => ({...prev, addPreset: true}))
            }}
          >
            {`+ ${intl.formatMessage({id: 'NEW_PRESET'})}`}
          </div>
        </div>
      </TableHeader>
    </>
  )
}

export {PresetFilter}
