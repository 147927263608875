import clsx from 'clsx'
import {cloneDeep, isArray, remove} from 'lodash'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Button} from './Button'

type Props = {
  name: string
  placeholder?: string
  className?: string
  dataOptions?: any
  valueOptions: any
  handleSetValueOptions?: any
  isSearch?: boolean
  idDelete?: boolean
  handleEventItem?: any
  hasClearValue?: boolean
  firstOption?: boolean
}

const SelectMultiDropdown: FC<Props> = ({
  name = '',
  placeholder = '',
  className,
  dataOptions = [],
  valueOptions,
  handleSetValueOptions,
  isSearch = false,
  idDelete = false,
  handleEventItem,
  hasClearValue = false,
  firstOption = false,
}) => {
  const intl = useIntl()
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [isClearValue, setIsClearValue] = useState(false)
  const [visibleSubMenu, setVisibleSubMenu] = useState<any>([])
  const filterOrderRef = useRef<any>(null)
  const [valueSearch, setValueSearch] = useState('')

  const findObjectByValue = useMemo(() => {
    return (arr, targetValue) => {
      const found: any = []
      for (const obj of arr) {
        if (valueOptions?.split(',')?.includes(obj.value)) {
          return obj
        }

        if (obj?.children) {
          const result = findObjectByValue(obj.children, targetValue)
          if (result.value) {
            found.push({parent: obj, result: result})
          }
        }
      }
      return found
    }
  }, [valueOptions])

  const foundedValue = useMemo(() => {
    return findObjectByValue(dataOptions, valueOptions)
  }, [dataOptions, findObjectByValue, valueOptions])

  const handleDropdownClick = () => {
    const arrChose: any = []
    let _foundedValue = foundedValue
    if (!isArray(_foundedValue)) {
      _foundedValue = [_foundedValue]
    }
    _foundedValue?.forEach((item) => {
      arrChose.push(item?.parent?.value || item?.value)
    })
    setVisibleSubMenu(arrChose)
    setVisibleMenu(!visibleMenu)
  }

  const resetDropdown = () => {
    setVisibleMenu(false)
    setVisibleSubMenu(undefined)
  }

  const customsData = useMemo(() => {
    if (!valueSearch) return dataOptions
    return cloneDeep(dataOptions).filter(({label}) => {
      return label.toLowerCase().includes(valueSearch.toLowerCase().trim())
    })
  }, [dataOptions, valueSearch])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterOrderRef.current && !filterOrderRef.current.contains(event.target)) {
        resetDropdown()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleFilterMultiValue = (parent: string, child: string) => {
    const arrParent = parent?.split(',') || []
    const indexB = arrParent.indexOf(child)
    if (indexB !== -1) {
      arrParent.splice(indexB, 1)
    } else {
      arrParent.push(child)
    }
    const result = arrParent.join(',')
    if (!result) {
      resetDropdown()
    }
    handleSetValueOptions(result)
  }

  const handleDeleteField = () => {
    setIsClearValue(true)
    handleSetValueOptions('')
    setTimeout(() => {
      resetDropdown()
      setIsClearValue(false)
    }, 1000)
  }

  const renderMenuItem = (data, index) => {
    const {value, label, children} = data

    if (children) {
      return (
        <div
          className={clsx('dropdown-submenu', {
            show: visibleSubMenu?.includes(value),
          })}
          key={index}
        >
          <div
            className={clsx('dropdown-submenu__item', {
              show: visibleSubMenu?.includes(value),
              'bg-light': valueOptions?.split(',')?.includes(value),
            })}
            onClick={() =>
              visibleSubMenu?.includes(value)
                ? setVisibleSubMenu(remove(visibleSubMenu, value))
                : setVisibleSubMenu(value)
            }
          >
            {intl.formatMessage({id: label})}
          </div>
          <div
            className={clsx(`dropdown-submenu__inner`, {
              show: visibleSubMenu?.includes(value),
            })}
          >
            {children.map((subitem, key) => {
              return (
                <>
                  <div
                    key={key}
                    className={clsx('dropdown-submenu__inner__item', {
                      'bg-primary text-white': valueOptions?.split(',')?.includes(subitem.value),
                      'text-hover-primary': !valueOptions,
                    })}
                  >
                    <div className='form-check form-check-custom form-check-sm form-check-primary'>
                      <input
                        type='checkbox'
                        className='me-2 cursor-pointer form-check-input'
                        checked={valueOptions?.split(',')?.includes(subitem.value) || false}
                        onChange={() => handleFilterMultiValue(valueOptions, subitem.value)}
                      />
                    </div>
                    <div
                      className='flex-fill text-nowrap text-truncate'
                      onClick={() => handleFilterMultiValue(valueOptions, subitem.value)}
                    >
                      {subitem.label}
                    </div>
                    {idDelete && (
                      <div
                        onClick={handleEventItem}
                        className='text-hover-danger bg-gradient rounded-circle p-2'
                      >
                        <KTSVG
                          path='/media/gori/orders/delete.svg'
                          className='opacity-50'
                          svgClassName='mh-10px'
                        />
                      </div>
                    )}
                  </div>
                </>
              )
            })}
          </div>
        </div>
      )
    }

    return (
      <div key={index} className={clsx('dropdown-submenu')}>
        <div
          className={clsx('dropdown-submenu__item', {
            'fw-bolder text-gray-600 bg-gray-200 text-hover-primary': firstOption && index === 0,
            'bg-primary text-white': valueOptions?.split(',')?.includes(value),
          })}
        >
          <div className='form-check form-check-custom form-check-sm form-check-primary'>
            <input
              type='checkbox'
              className='me-2 cursor-pointer form-check-input'
              checked={valueOptions?.split(',')?.includes(value) || false}
              onChange={() => handleFilterMultiValue(valueOptions, value)}
            />
          </div>
          <div
            className={clsx('flex-fill text-nowrap text-truncate', {
              'text-white': firstOption && index === 0 && valueOptions?.split(',')?.includes(value),
            })}
            onClick={() => {
              handleFilterMultiValue(valueOptions, value)
            }}
          >
            {label}
          </div>
          {idDelete && (
            <div
              onClick={handleEventItem}
              className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
            >
              <KTSVG
                path='/media/gori/orders/delete.svg'
                className='opacity-50'
                svgClassName='mh-10px'
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={clsx('dropdown', className, {
          'border border-2 border-primary rounded-2': valueOptions,
        })}
        ref={filterOrderRef}
      >
        <div
          className={clsx('dropdown__label btn btn-sm btn-white btn-active-light-primary border')}
          onClick={handleDropdownClick}
        >
          <div className='dropdown__label__left fw-bold'>
            <span className='fw-bolder fs-5 text-gray-600'>{name}</span>
          </div>
          {valueOptions ? (
            <>
              <span className='dropdown__label__value'>
                {`${valueOptions?.split(',')?.length || 0} ${intl.formatMessage({id: 'SELECTED'})}`}
              </span>
              {hasClearValue && (
                <Button
                  event={handleDeleteField}
                  className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
                >
                  <KTSVG
                    path='/media/gori/orders/delete.svg'
                    className='opacity-50 m-0'
                    svgClassName='mh-10px'
                  />
                </Button>
              )}
            </>
          ) : (
            <i className='bi bi-caret-down-fill ms-2'></i>
          )}
        </div>
        <div className={clsx('dropdown-menu dropdown__menu', {show: visibleMenu && !isClearValue})}>
          <div className={clsx('dropdown-submenu')}>
            <div className='sticky-top bg-white'>
              {name && <div className='p-4 bg-light fw-bold fs-5'>{name}</div>}
              {isSearch && (
                <div className='mx-3 my-2'>
                  <input
                    type='text'
                    autoComplete='off'
                    className='form-control'
                    placeholder={placeholder}
                    onChange={(e) => {
                      setValueSearch(e.target.value)
                    }}
                    value={valueSearch}
                    autoFocus
                  />
                </div>
              )}
            </div>
            {customsData.map((item, index) => renderMenuItem(item, index))}
          </div>
        </div>
      </div>
    </>
  )
}

export {SelectMultiDropdown}
