import clsx from 'clsx'
import {cloneDeep, isEmpty, isEqual, last, size} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {convertCurrency, KTSVG} from '../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {OrderStatus} from '../../core/_const'

type Props = {
  data: any
  handleClickRate: any
  disabled?: boolean
  dataShipment?: any
}

const Rates: React.FC<Props> = ({data, handleClickRate, disabled = false, dataShipment = null}) => {
  const intl = useIntl()
  const {statesGlobal} = useStatesGlobal()
  const carriers = statesGlobal.carriers

  const matchingPackages = cloneDeep(data?.options)?.filter((item) => item?.matching_package) || []

  const renderErrors = useCallback(
    (item) => {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id='tooltip-disabled'>
              <span className='text-danger'>
                {item?.error ? item?.error : intl.formatMessage({id: 'ERROR'})}
              </span>
            </Tooltip>
          }
        >
          <span>
            <KTSVG
              path='/media/gori/settings/errors.svg'
              className='ms-2 mb-1 svg-icon-4 cursor-pointer'
            />
          </span>
        </OverlayTrigger>
      )
    },
    [intl]
  )

  const renderItemService = (item, index) => {
    return (
      <div>
        <div
          className={clsx('btn border d-flex justify-content-between my-5', {
            'border-primary bg-light-primary': isEqual(item, data?.chooseRate),
            'border-secondary pe-none opacity-75': disabled && !isEqual(item, data?.chooseRate),
            'border-secondary opacity-75 cursor-no-drop': item?.error,
          })}
          key={index}
          onClick={() => !item?.error && handleClickRate(item)}
        >
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-40px me-5'>
              <span className='symbol-label bg-transparent'>
                <img src={item?.logo} className='h-100 align-self-center' alt='logo-shipment' />
              </span>
            </div>
            <span>
              <span className='text-gray-800 fs-6 fw-bolder'>
                {item.display_name}
                {item?.error && renderErrors(item)}
              </span>
              {item?.package_type?.label && (
                <span className='m-1 badge badge-pill badge-light'>
                  {item?.package_type?.label}
                </span>
              )}
            </span>
          </div>
          {item?.rate && (
            <div className='d-flex align-items-center'>
              <span className='fs-4 fw-boldest py-4 px-4'>{convertCurrency(item?.rate)}</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderItemServiceShipped = useMemo(() => {
    const latestShipment: any = last(dataShipment?.shipments) || null
    if (!latestShipment) return null

    let service = carriers?.[latestShipment?.carrier + '_' + latestShipment?.service] ?? []
    if (!latestShipment || !service) return null

    return (
      <div>
        <div
          className={clsx(
            'btn border d-flex justify-content-between my-5 border-secondary pe-none'
          )}
        >
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-40px me-5'>
              <span className='symbol-label bg-transparent'>
                <img
                  src={service?.visuals?.logo}
                  className='h-100 align-self-center'
                  alt='logo-shipment'
                />
              </span>
            </div>
            <span>
              <span className='text-gray-800 fs-6 fw-bolder'>
                {service?.visuals?.display_name}
                {service?.error && renderErrors(service)}
              </span>
              {service?.package_type?.label && (
                <span className='m-1 badge badge-pill badge-light'>
                  {service?.package_type?.label}
                </span>
              )}
            </span>
          </div>
          {latestShipment?.fees?.items?.[0]?.amount && (
            <div className='d-flex align-items-center'>
              <span className='fs-4 fw-boldest py-4 px-4'>
                {convertCurrency(latestShipment?.fees?.items?.[0]?.amount)}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }, [carriers, dataShipment?.shipments, renderErrors])

  const cheapest = cloneDeep(data?.options)?.filter(
    (item) => !item?.matching_package && item.cheapest
  )
  const moreRates = cloneDeep(data?.options)?.filter(
    (item) => !item?.matching_package && !item.cheapest
  )

  return (
    <div className={clsx({'cursor-no-drop': disabled})}>
      {dataShipment?.status === OrderStatus.SHIPPED && renderItemServiceShipped}
      {(size(cheapest) > 0 || size(matchingPackages) > 0) &&
        dataShipment?.status !== OrderStatus.SHIPPED && (
          <>
            <span className='fw-bolder fw-bold fs-7'>
              {intl.formatMessage({id: isEmpty(matchingPackages) ? 'CHEAPEST' : 'FLAT_RATE'})}
            </span>
            <div>
              {(isEmpty(matchingPackages) ? cheapest : matchingPackages).map((item, idx) =>
                renderItemService(item, idx)
              )}
            </div>
          </>
        )}
      {size(moreRates) > 0 && dataShipment?.status !== OrderStatus.SHIPPED && (
        <>
          <span className='fw-bolder fw-bold fs-7'>{intl.formatMessage({id: 'MORE_RATES'})}</span>
          {!isEmpty(matchingPackages) && !isEmpty(cheapest) && (
            <div>{cheapest?.map((item, idx) => renderItemService(item, idx))}</div>
          )}
          <div>{moreRates.map((item, idx) => renderItemService(item, idx))}</div>
        </>
      )}
    </div>
  )
}

export {Rates}
