import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {cloneDeep, compact, every, isEmpty, isEqual, pick, size, values} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ShowAddress} from '../../../../../../_gori/components'
import {KTSVG} from '../../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  SelectMultiLevelFormik,
  ValidationErrorModal,
} from '../../../../../../_gori/partials/widgets'
import {PackagesMenu} from '../../../../orders/components/PackagesMenu'
import {
  OPTION_DIMENSION_UNIT,
  OPTION_PACKAGE_TYPE,
  OPTION_WEIGHT_UNIT,
  OrdersConfig,
} from '../../../../orders/core/_const'
import {SettingsConfig} from '../../../core/_const'
import SettingsService from '../../../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  dataEditPreset: any
  reloadTable: any
}

const EditPresetModal: React.FC<Props> = ({show, handleClose, dataEditPreset, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, numberYup, orderYup, regexYup, infoYup} = UseYupValidate()
  const {statesGlobal, getPackages, getAddress} = useStatesGlobal()
  const carriers = statesGlobal.carriers
  const address = statesGlobal.address
  const packages = statesGlobal.packages
  const presetRef = useRef<any>(null)
  const [messageErrors, setMessageErrors] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<{update: boolean}>({
    update: false,
  })
  const [showAddOptions, setShowAddOptions] = useState(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [optionsCustomPackage, setOptionsCustomPackage] = useState<any>([])
  const [optionsAddress, setOptionsAddress] = useState<any>([])
  const [actionPreset, setActionPreset] = useState<{name: string; action: boolean}>({
    name: '',
    action: false,
  }) //action<true: add, false: delete>
  const row = useRef({parcels: 0})
  const [internationalServiceUS, setInternationalServiceUS] = useState<{
    militaries: boolean
    territories: boolean
  }>({militaries: false, territories: false})
  const renderFirst = useRef(true)
  const optionCurrent = useRef<any>(null)
  const serviceCurrent = useRef(null)

  const hasTruthyValue = (object) => !every(values(object), isEmpty)

  const presetInit = useMemo(() => {
    return Object.values(cloneDeep(SettingsConfig.SHOW_PRESET)).map((field) => {
      const data = dataEditPreset?.shipping_options
      switch (field.name) {
        case SettingsConfig.SHOW_PRESET.SHIP_FROM.name:
          if (hasTruthyValue(data?.from)) {
            field.show = true
          } else {
            field.show = false
          }
          break
        case SettingsConfig.SHOW_PRESET.SHIP_TO.name:
          if (hasTruthyValue(data?.to)) {
            field.show = true
          } else {
            field.show = false
          }
          break
        case SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name:
          if (data?.service) {
            field.show = true
          } else {
            field.show = false
          }
          break
        case SettingsConfig.SHOW_PRESET.PACKAGE_TYPE.name:
          if (data?.parcels?.[0]?.package_id || data?.parcels?.[0]?.package_type) {
            field.show = true
          } else {
            field.show = false
          }
          break
        case SettingsConfig.SHOW_PRESET.SIZE.name:
          if (
            data?.parcels?.[0]?.dimension_unit &&
            data?.parcels?.[0]?.length &&
            data?.parcels?.[0]?.width &&
            data?.parcels?.[0]?.height
          ) {
            field.show = true
          } else {
            field.show = false
          }
          break
        case SettingsConfig.SHOW_PRESET.WEIGHT.name:
          if (data?.parcels?.[0]?.weight_unit && data?.parcels?.[0]?.weight) {
            field.show = true
          } else {
            field.show = false
          }
          break
      }
      return field
    })
  }, [dataEditPreset?.shipping_options])
  const [showPresetsInit, setShowPresetInit] = useState(
    cloneDeep(Object.assign(dataEditPreset?.shipping_options?.show_presets, presetInit))
  )

  // BEGIN: Modal
  const handleCloseModal = () => {
    setShowPresetInit(cloneDeep(SettingsConfig.SHOW_PRESET))
    handleClose()
    formik.resetForm()
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (presetRef.current && !presetRef.current.contains(event.target)) {
        setShowAddOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  // END: Modal

  // BEGIN: Formik
  const validateAddress = useCallback(
    (name) => {
      const validate = {
        [`${name}_company`]: Yup.string().when([`${name}_first_name`], {
          is: (first_name) => !first_name || first_name.trim() === '',
          then: orderYup.companyFirstName('COMPANY'),
          otherwise: stringYup(255, 'COMPANY', false),
        }),
        [`${name}_first_name`]: Yup.string().when(`${name}_company`, {
          is: (company) => !company || company.trim() === '',
          then: orderYup.companyFirstName('FIRST_NAME'),
          otherwise: stringYup(255, 'FIRST_NAME', false),
        }),
        [`${name}_street1`]: stringYup(255, 'ADDRESS'),
        [`${name}_city`]: stringYup(255, 'CITY'),
        [`${name}_state`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.state(),
          otherwise: stringYup(255, 'STATE'),
        }),
        [`${name}_country`]: regexYup.country,
        [`${name}_zip`]: Yup.string().when(`${name}_country`, {
          is: (val) => val === 'US',
          then: regexYup.zipCode(),
          otherwise: stringYup(255, 'POSTAL_CODE'),
        }),
        [`${name}_last_name`]: stringYup(255, 'LAST_NAME', false),
        [`${name}_street2`]: stringYup(255, 'ADDRESS_2', false),
        [`${name}_phone`]: infoYup.phone('PHONE', false),
        [`${name}_email`]: infoYup.email('EMAIL', false),
      }
      return validate
    },
    [infoYup, orderYup, regexYup, stringYup]
  )

  const [validateSchema, setValidateSchema] = useState<any>({})
  const validationSchema = useMemo(() => Yup.object().shape(validateSchema), [validateSchema])

  const initValueEdit = useCallback((name, init) => {
    switch (name) {
      case 'parcels':
        init[name] = {
          0: {
            package_id: null,
            package_type: '',
            length: '',
            width: '',
            height: '',
            weight: '',
            dimension_unit: 'in',
            weight_unit: 'oz',
          },
        }
        break
      default:
        break
    }
  }, [])

  const initialValues = useMemo(() => {
    const init: any = Object.entries(OrdersConfig.SHIPPING).reduce(
      (initValue, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
            initValue.from[`from_${value.value}`] = false
            initValue.to[`to_${value.value}`] = true
            break
          case OrdersConfig.SHIPPING.VERIFIED.value:
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            break
          default:
            initValue.from[`from_${value.value}`] = ''
            initValue.to[`to_${value.value}`] = ''
            break
        }
        return initValue
      },
      {from: {}, to: {}}
    )
    init.name = ''
    initValueEdit('parcels', init)
    init.service = ''

    return init
  }, [initValueEdit])

  const formik = useFormik({
    initialValues: {
      name: dataEditPreset.name,
      from: dataEditPreset.shipping_options?.from,
      to: dataEditPreset.shipping_options?.to,
      parcels: {...dataEditPreset.shipping_options?.parcels},
      service: dataEditPreset.shipping_options?.service,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (size(showFromPreset.filter((field) => field.show)) <= 1) {
        return setMessageErrors('PLEASE_SELECT_AT_LEAST_ONE_PRESET_OPTION')
      }
      let shippingOptions = cloneDeep(initialValues)
      let parcels
      for (let i = 0; i < showFromPreset.length; i++) {
        if (showFromPreset[i].show) {
          switch (showFromPreset[i].name) {
            case SettingsConfig.SHOW_PRESET.SHIP_FROM.name:
              shippingOptions['from'] = values.from
              break
            case SettingsConfig.SHOW_PRESET.SHIP_TO.name:
              shippingOptions['to'] = values.to
              break
            case SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name:
              shippingOptions['service'] = values.service
              break
            case SettingsConfig.SHOW_PRESET.SIZE.name:
              shippingOptions['parcels'][row.current.parcels] = {
                ...shippingOptions['parcels'][row.current.parcels],
                ...pick(values.parcels[0], ['length', 'width', 'height', 'dimension_unit']),
              }
              break
            case SettingsConfig.SHOW_PRESET.WEIGHT.name:
              shippingOptions['parcels'][row.current.parcels] = {
                ...shippingOptions['parcels'][row.current.parcels],
                ...pick(values.parcels[0], ['weight', 'weight_unit', 'weight_limit']),
              }
              break
            case SettingsConfig.SHOW_PRESET.PACKAGE_TYPE.name:
              shippingOptions['parcels'][row.current.parcels] = {
                ...shippingOptions['parcels'][row.current.parcels],
                ...pick(values.parcels[0], [
                  'length',
                  'width',
                  'height',
                  'dimension_unit',
                  'package_id',
                  'package_type',
                ]),
              }
              break
            default:
              break
          }
        } else {
          switch (showFromPreset[i].name) {
            case SettingsConfig.SHOW_PRESET.SHIP_FROM.name:
              shippingOptions['from'] = initialValues.from
              break
            case SettingsConfig.SHOW_PRESET.SHIP_TO.name:
              shippingOptions['to'] = initialValues.to
              break
            case SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name:
              shippingOptions['service'] = initialValues.service
              break
            case SettingsConfig.SHOW_PRESET.SIZE.name:
              const valuesParcels = shippingOptions['parcels'][row.current.parcels]
              parcels =
                valuesParcels.package_id || valuesParcels.package_type
                  ? {...parcels}
                  : {...parcels, length: '', width: '', height: '', dimension_unit: ''}
              break
            case SettingsConfig.SHOW_PRESET.WEIGHT.name:
              parcels = {...parcels, weight: '', weight_unit: '', weight_limit: ''}
              break
            case SettingsConfig.SHOW_PRESET.PACKAGE_TYPE.name:
              parcels = {...parcels, package_id: null, package_type: ''}
              break
            default:
              break
          }
        }
      }
      shippingOptions = {
        ...shippingOptions,
        parcels: {
          [row.current.parcels]: {...shippingOptions.parcels[row.current.parcels], ...parcels},
        },
      }

      const payload: any = {
        name: values.name,
        shipping_options: {...shippingOptions, show_presets: showFromPreset},
      }

      try {
        setLoading((prev) => ({...prev, update: true}))
        const {message} = await SettingsService.updateShippingPreset(dataEditPreset.id, payload, {
          cancelToken: newCancelToken(),
        })
        toast.success(intl.formatMessage({id: message}))
        handleClose()
        reloadTable()
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })
  // END: Formik

  // BEGIN: Field Address
  useEffect(() => {
    getAddress('from')
    getAddress('to')

    return () => {}
  }, [getAddress])

  useEffect(() => {
    const addressModified = {}
    Object.keys(address).forEach((typeName) => {
      const items = address[typeName]
      const optionAddress = items.map((item) => {
        const {first_name, last_name, company, street1, city, state, zip, is_verified} = item
        const fullName = compact([first_name, last_name]).join(', ')
        const labelOption = (
          <div>
            <div className='fw-bolder'>{company}</div>
            <div className='fs-7'>{fullName}</div>
            <div className='d-block fw-bold'>{compact([street1, city, state, zip]).join(', ')}</div>
            {is_verified && (
              <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
            )}
          </div>
        )

        let valueOption = {}
        const customValues = pick(item, [
          ...Object.values(cloneDeep(OrdersConfig.SHIPPING)).map((item) => {
            return item.value
          }),
        ])
        if (customValues) {
          valueOption = Object.entries(customValues).reduce((_values, [key, value]) => {
            switch (key) {
              case OrdersConfig.SHIPPING.RESIDENTIAL.value:
                _values[`${typeName}_${key}`] = value || false
                break
              case OrdersConfig.SHIPPING.VERIFIED.value:
              case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
                break
              default:
                _values[`${typeName}_${key}`] = value || ''
                break
            }
            return _values
          }, {})
        }

        return {
          label: labelOption,
          value: valueOption,
        }
      })
      addressModified[typeName] = optionAddress
    })
    setOptionsAddress(addressModified)

    return () => {
      setOptionsAddress([])
    }
  }, [address, intl])

  const checkValidateFailed = useCallback(
    (nameField) => {
      const validate = [
        `${nameField}_city`,
        `${nameField}_state`,
        `${nameField}_country`,
        `${nameField}_zip`,
        `${nameField}_street1`,
      ]
      return validate.some((item) => !formik.values?.[nameField]?.[item])
    },
    [formik.values]
  )
  // END: Field Address

  // BEGIN: Field service and package
  useEffect(() => {
    getPackages()

    return () => {}
  }, [getPackages])

  useEffect(() => {
    if (!packages) {
      setOptionsCustomPackage([
        {
          parent: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
          current: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          options: [],
        },
      ])
    } else {
      const _optionPackages = packages.map((item) => {
        let objOption = {label: '', value: {}}
        objOption.label = item.name
        objOption.value = {
          length: item.length,
          width: item.width,
          height: item.height,
          dimension_unit: item.dimension_unit,
          package_id: item.id,
          package_type: '',
        }
        return objOption
      })
      setOptionsCustomPackage([
        {
          parent: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          current: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE,
          options: _optionPackages,
        },
      ])
    }

    return () => {}
  }, [packages])

  const checkInternationalOption = useMemo(() => {
    const fromCountry = formik.values?.from?.from_country?.trim()
    const fromState = formik.values?.from?.from_state?.trim()
    const fromCity = formik.values?.from?.from_city?.trim()

    if (fromCountry && fromCountry !== 'US') {
      setInternationalServiceUS({territories: false, militaries: false})
      return true
    } else {
      if (OrdersConfig.US_TERRITORIES.includes(fromState)) {
        setInternationalServiceUS((prev) => ({...prev, territories: true}))
        return true
      } else {
        setInternationalServiceUS((prev) => ({...prev, territories: false}))
      }
      if (OrdersConfig.US_MILITARIES.includes(fromCity)) {
        setInternationalServiceUS((prev) => ({...prev, militaries: true}))
        return true
      } else {
        setInternationalServiceUS((prev) => ({...prev, militaries: false}))
      }
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
  }, [
    formik.values?.from?.from_country,
    formik.values?.from?.from_state,
    formik.values?.from?.from_city,
  ])

  const optionsService = useMemo(() => {
    if (isEmpty(carriers)) return

    const international = Object.values(cloneDeep(carriers)).filter(
      (value: any) => value?.international && value?.status
    )
    const noInternational = Object.values(cloneDeep(carriers)).filter(
      (value: any) => !value?.international && value?.status
    )
    const _internationalServiceUS = Object.values(cloneDeep(carriers)).filter((value: any) => {
      const carrierService = `${value.carrier}_${value.service}`
      if (!value?.international && value?.status) {
        if (
          internationalServiceUS.militaries &&
          OrdersConfig.SERVICE_FILTER.MILITARIES.includes(carrierService)
        ) {
          return value
        } else if (
          internationalServiceUS.territories &&
          OrdersConfig.SERVICE_FILTER.TERRITORIES.includes(carrierService)
        ) {
          return value
        }
      }
      return null
    })

    const _options = Object.values(
      checkInternationalOption
        ? internationalServiceUS.militaries || internationalServiceUS.territories
          ? _internationalServiceUS
          : international
        : noInternational
    ).map((value: any) => {
      value.label = value.visuals?.display_name
      value.value = `${value.carrier}_${value.service}`
      return value
    })

    return [
      {
        label: intl.formatMessage({
          id: checkInternationalOption
            ? internationalServiceUS.militaries || internationalServiceUS.territories
              ? 'DOMESTIC'
              : 'INTERNATIONAL'
            : 'DOMESTIC',
        }),
        options: _options,
      },
    ]
  }, [carriers, checkInternationalOption, internationalServiceUS, intl])

  useEffect(() => {
    if (renderFirst.current) {
      renderFirst.current = false
      optionCurrent.current = optionsService
      return
    }

    if (!isEqual(optionCurrent.current, optionsService)) {
      formik.setFieldValue('service', '')
      optionCurrent.current = optionsService
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsService])

  const optionsPackageTypes = useMemo(() => {
    const packageType = formik.values?.parcels?.[0]?.package_type
    if (
      typeof packageType === 'string' &&
      packageType &&
      serviceCurrent.current !== formik.values?.service
    ) {
      const initObj = {
        ...formik.values?.parcels?.[0],
        length: '',
        width: '',
        height: '',
        dimension_unit: 'in',
        package_type: '',
        package_id: null,
      }
      formik.setFieldValue('parcels.0', initObj)
    }
    serviceCurrent.current = formik.values?.service

    const _option = carriers[formik.values?.service]?.package_types
    if (isEmpty(_option)) return []

    return [
      {
        parent: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
        current: OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE,
        options: _option.map((item: any) => {
          return {
            label: item.label,
            value: {...item.parcel, package_type: item.value, package_id: null},
          }
        }),
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers, formik.values?.service])

  useMemo(() => {
    if (typeof formik.values?.parcels[0]?.package_type === 'object') {
      formik.setFieldValue('parcels.0', {
        ...formik.values.parcels[0],
        ...formik.values.parcels[0].package_type,
      })
    } else if (formik.values?.parcels[0]?.package_type) {
      formik.setFieldValue('parcels.0', {
        ...formik.values.parcels[0],
        package_type: formik.values.parcels[0].package_type,
        package_id: null,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.parcels[0]?.package_type])
  // END: Field service and package

  // BEGIN: Add Preset
  const showFromPreset = useMemo(() => {
    const updateData = Object.values(showPresetsInit).map((preset: any) => {
      if (preset.name === actionPreset?.name) {
        preset.show = actionPreset?.action
      }
      return preset
    })
    setMessageErrors(undefined)
    return updateData
  }, [actionPreset, showPresetsInit])

  useEffect(() => {
    formik.setErrors({})
    setMessageErrors(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    const newValidate = {}
    for (let i = 0; i < showFromPreset.length; i++) {
      if (showFromPreset[i].show) {
        switch (showFromPreset[i].name) {
          case SettingsConfig.SHOW_PRESET.PRESET_NAME.name:
            newValidate['name'] = stringYup(255, 'PRESET_NAME')
            break
          case SettingsConfig.SHOW_PRESET.SHIP_FROM.name:
            newValidate['from'] = Yup.object().shape(
              Object.entries(validateAddress('from')).reduce((validate, [key, value]) => {
                validate[key] = value
                return validate
              }, {}),
              [['from_first_name', 'from_company']]
            )
            break
          case SettingsConfig.SHOW_PRESET.SHIP_TO.name:
            newValidate['to'] = Yup.object().shape(
              Object.entries(validateAddress('to')).reduce((validate, [key, value]) => {
                validate[key] = value
                return validate
              }, {}),
              [['to_first_name', 'to_company']]
            )
            break
          case SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name:
            newValidate['service'] = stringYup(50, 'SERVICE')
            break
          case SettingsConfig.SHOW_PRESET.SIZE.name:
            newValidate['parcels'] = Yup.object().shape({
              [row.current.parcels]: Yup.object().shape({
                ...newValidate?.['parcels']?.[row.current.parcels]?.fields,
                ...OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
                  switch (currentName) {
                    case 'length':
                    case 'width':
                    case 'height':
                      initParcel[currentName] = numberYup.parcel(currentName)
                      break
                    case 'dimension_unit':
                      initParcel[currentName] = stringYup(50, 'UNIT')
                      break
                  }
                  return initParcel
                }, {}),
              }),
            })
            break
          case SettingsConfig.SHOW_PRESET.WEIGHT.name:
            newValidate['parcels'] = Yup.object().shape({
              [row.current.parcels]: Yup.object().shape({
                ...newValidate?.['parcels']?.fields?.[row.current.parcels]?.fields,
                ...OrdersConfig.PARCEL.reduce((initParcel, currentName) => {
                  switch (currentName) {
                    case 'weight':
                      initParcel[currentName] = numberYup.parcel(
                        currentName,
                        true,
                        formik.values.parcels[0]?.package_type ? 'hasPackage' : ''
                      )
                      break
                    case 'weight_unit':
                      initParcel[currentName] = stringYup(50, 'UNIT')
                      break
                  }
                  return initParcel
                }, {}),
              }),
            })
            break
          default:
            break
        }
      }
    }
    setValidateSchema(newValidate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.parcels[0]?.package_type, showFromPreset])
  // END: Add Preset

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_create_package'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'EDIT_PRESET'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='row my-4' key='name'>
                <div className='col-11 d-flex align-items-start'>
                  <InputTextFormik
                    className='flex-fill'
                    labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                    label={intl.formatMessage({id: 'PRESET_NAME'})}
                    formik={formik}
                    name='name'
                    required
                  />
                </div>
                <div className='col-1'></div>
              </div>
              {showFromPreset
                .filter((preset) => preset.show)
                .map((item) => {
                  switch (item.name) {
                    case SettingsConfig.SHOW_PRESET.SHIP_FROM.name:
                    case SettingsConfig.SHOW_PRESET.SHIP_TO.name:
                      const nameField = item?.name?.split('_')?.[1]?.toLowerCase()
                      return (
                        <div className='row my-4' key={item.key}>
                          <div className='col-11 d-flex align-items-center'>
                            <SelectFormik
                              className={'flex-fill'}
                              labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                              label={intl.formatMessage({id: item.name})}
                              placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                              formik={formik}
                              name={nameField}
                              required
                              options={optionsAddress[nameField]}
                              isSearchable={false}
                              emptyDefault={false}
                              showMessageError={false}
                            />
                          </div>
                          <div className='col-1'>
                            <div
                              className={clsx(
                                'd-flex justify-content-center text-hover-danger cursor-pointer '
                              )}
                              onClick={() =>
                                setActionPreset({
                                  name: item.name,
                                  action: false,
                                })
                              }
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          </div>
                          <div className='col-11 d-flex'>
                            <div className='col-3 flex-fill'></div>
                            <div className='col-9'>
                              {!isEmpty(formik.getFieldProps(nameField).value) &&
                                !optionsAddress?.[nameField]?.find((option) =>
                                  isEqual(option.value, formik.values?.[nameField])
                                ) && (
                                  <div className='px-4 mt-2'>
                                    <ShowAddress data={formik.values[nameField]} name={nameField} />
                                    {formik.getFieldProps(`${nameField}.${nameField}_is_verified`)
                                      .value ? (
                                      <div className='text-success mt-2'>
                                        {intl.formatMessage({id: 'VERIFIED_ADDRESS'})}
                                      </div>
                                    ) : null}
                                  </div>
                                )}
                              {checkValidateFailed(nameField) &&
                                getIn(formik.touched, nameField) &&
                                getIn(formik.errors, nameField) && (
                                  <span className='text-danger'>
                                    {intl.formatMessage(
                                      {id: 'INPUT_IS_REQUIRED'},
                                      {
                                        input: intl.formatMessage({
                                          id: item.name,
                                        }),
                                      }
                                    )}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                    case SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name:
                      return (
                        <div className='row my-4' key={item.key}>
                          <div className='col-11 d-flex align-items-center'>
                            <SelectMultiLevelFormik
                              className='col-9'
                              labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                              label={intl.formatMessage({id: 'SERVICE'})}
                              formik={formik}
                              name='service'
                              required
                              options={optionsService || [{label: '', options: []}]}
                            />
                          </div>
                          <div className='col-1'>
                            <div
                              className={clsx(
                                'd-flex justify-content-center text-hover-danger cursor-pointer '
                              )}
                              onClick={() => {
                                setActionPreset({
                                  name: SettingsConfig.SHOW_PRESET.SHIPPING_SERVICE.name,
                                  action: false,
                                })
                              }}
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          </div>
                        </div>
                      )
                    case SettingsConfig.SHOW_PRESET.PACKAGE_TYPE.name:
                      return (
                        <>
                          <div className='row mt-4' key={item.name}>
                            <div className='col-11 d-flex align-items-center'>
                              <PackagesMenu
                                labelClassName='col-3 col-form-label text-gray-600 fw-bolder'
                                textLabel={intl.formatMessage({id: 'PACKAGE_TYPE'})}
                                placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                                data={[
                                  ...cloneDeep(optionsPackageTypes),
                                  ...cloneDeep(optionsCustomPackage),
                                ]}
                                formik={formik}
                                name='parcels.0.package_type'
                                showAddPackage={false}
                                showInputDimension={false}
                              />
                            </div>
                            <div className='col-1'>
                              <div
                                className={clsx(
                                  'd-flex justify-content-center text-hover-danger cursor-pointer '
                                )}
                                onClick={() =>
                                  setActionPreset({
                                    name: SettingsConfig.SHOW_PRESET.PACKAGE_TYPE.name,
                                    action: false,
                                  })
                                }
                              >
                                <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                              </div>
                            </div>
                          </div>
                          {(formik.values?.parcels?.[0]?.package_id ||
                            formik.values?.parcels?.[0]?.package_type) && (
                            <div className='col-11 d-flex'>
                              <div className='col-3'></div>
                              <div className='flex-fill'>
                                {(formik.values.parcels[0].length ||
                                  formik.values.parcels[0].width ||
                                  formik.values.parcels[0].height) && (
                                  <span className='text-muted mt-1'>
                                    {intl.formatMessage({id: 'LENGTH'})}:{' '}
                                    {formik.values.parcels[0]?.length}{' '}
                                    {intl.formatMessage({id: 'WIDTH'})}:{' '}
                                    {formik.values.parcels[0]?.width}{' '}
                                    {intl.formatMessage({id: 'HEIGHT'})}:{' '}
                                    {formik.values.parcels[0]?.height} (
                                    {formik.values.parcels[0]?.dimension_unit})
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )
                    case SettingsConfig.SHOW_PRESET.SIZE.name:
                      return (
                        <div className='row my-4' key={item.key}>
                          <div className='col-11 d-flex'>
                            {['length', 'width', 'height'].map((item, index) => (
                              <div className={clsx('col-3')} key={index}>
                                <InputTextFormik
                                  type='number'
                                  min={0}
                                  key={index}
                                  className='mx-1 flex-fill'
                                  labelClassName='col-form-label text-gray-600 fw-bolder'
                                  label={intl.formatMessage({id: item.toUpperCase()})}
                                  formik={formik}
                                  name={`parcels.0.${item}`}
                                  required
                                />
                              </div>
                            ))}
                            <div className='col-3'>
                              <SelectFormik
                                className='ms-1'
                                label={intl.formatMessage({id: 'UNIT'})}
                                labelClassName={'col-form-label text-gray-600 fw-bolder'}
                                options={OPTION_DIMENSION_UNIT}
                                name={'parcels.0.dimension_unit'}
                                formik={formik}
                                placeholder=''
                                required
                              />
                            </div>
                          </div>
                          <div className='col-1'>
                            <div
                              className={clsx(
                                'd-flex justify-content-center text-hover-danger cursor-pointer'
                              )}
                              onClick={() =>
                                setActionPreset({
                                  name: SettingsConfig.SHOW_PRESET.SIZE.name,
                                  action: false,
                                })
                              }
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          </div>
                        </div>
                      )
                    case SettingsConfig.SHOW_PRESET.WEIGHT.name:
                      return (
                        <div className='row my-4' key={item.key}>
                          <div className='d-flex col-11'>
                            <div className='col-6'>
                              <InputTextFormik
                                type='number'
                                min={0}
                                className='flex-fill me-2'
                                labelClassName='col-form-label text-gray-600 fw-bolder'
                                label={intl.formatMessage({id: 'WEIGHT'})}
                                formik={formik}
                                name={'parcels.0.weight'}
                                required
                              />
                            </div>
                            <div className='col-6'>
                              <SelectFormik
                                label={intl.formatMessage({id: 'UNIT'})}
                                labelClassName={'col-form-label text-gray-600 fw-bolder'}
                                className='flex-fill'
                                options={OPTION_WEIGHT_UNIT}
                                formik={formik}
                                name={'parcels.0.weight_unit'}
                                placeholder=''
                                required
                              />
                            </div>
                          </div>
                          <div className='col-1'>
                            <div
                              className={clsx(
                                'd-flex justify-content-center text-hover-danger cursor-pointer'
                              )}
                              onClick={() =>
                                setActionPreset({
                                  name: SettingsConfig.SHOW_PRESET.WEIGHT.name,
                                  action: false,
                                })
                              }
                            >
                              <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                            </div>
                          </div>
                        </div>
                      )
                    default:
                      return null
                  }
                })}
              <div
                className={clsx('position-relative', {
                  'cursor-no-drop': showFromPreset.filter((item) => !item.show).length <= 0,
                })}
                ref={presetRef}
              >
                <div
                  className={clsx(
                    'd-flex flex-fill justify-content-center btn fs-5 fw-bolder mt-4',
                    {
                      'pe-none bg-secondary text-dark':
                        showFromPreset.filter((item) => !item.show).length <= 0,
                      'bg-primary text-white': showAddOptions,
                      'bg-light-primary text-primary': !showAddOptions,
                    }
                  )}
                  onClick={() => setShowAddOptions(!showAddOptions)}
                >
                  {intl.formatMessage({id: 'ADD_SHIPPING_OPTIONS'})} +
                </div>
                <div className='position-absolute w-100 z-index-1 bg-white rounded-2'>
                  {showAddOptions &&
                    showFromPreset
                      .filter((preset) => !preset.show)
                      .map((item, index) => {
                        return (
                          <div
                            key={index}
                            className='bg-light-primary my-1 p-2 px-6 rounded-2 fw-bold fs-5 bg-hover-light'
                            onClick={() => {
                              formik.setTouched({})
                              setActionPreset({name: item.name, action: true})
                              setShowAddOptions(!showAddOptions)
                            }}
                          >
                            {intl.formatMessage({id: item.name})}
                          </div>
                        )
                      })}
                </div>
              </div>
              {!isEmpty(messageErrors) && (
                <div className='bg-light-danger mt-4 py-2 px-4 text-dark d-flex align-items-center'>
                  <KTSVG path='/media/gori/settings/errors.svg' small={false} />
                  <span className='fs-5 mx-3 fw-bold'>{intl.formatMessage({id: 'ERROR'})}</span>
                  {intl.formatMessage({id: messageErrors})}
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  className='btn btn-secondary me-2'
                  label={intl.formatMessage({id: 'CANCEL'})}
                  loadingText={intl.formatMessage({id: 'CANCEL'})}
                  event={handleCloseModal}
                  disabled={loading.update}
                />
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SAVE'})}
                  loadingText={intl.formatMessage({id: 'SAVE'})}
                  event={formik.submitForm}
                  disabled={loading.update}
                  loading={loading.update}
                />
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {EditPresetModal}
