import {isEmpty} from 'lodash'
import {read, utils} from 'xlsx'
import {OrdersConfig} from '../../app/modules/orders/core/_const'

const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

const allowTestOnly = (currentUser) => {
  return currentUser?.username === 'test' || currentUser?.username === 'admin'
}

const setLoadingActionTable = (
  tableData: Array<object>,
  listId: Array<number>,
  status: boolean,
  key = 'isLoading'
) => {
  const setIsLoading = tableData.filter((row: any) => {
    listId.forEach((id: number) => {
      if (row.id === id) {
        row[key] = status
      }
    })
    return listId.includes(row.id)
  })

  return tableData.map((row: any) => setIsLoading.find((item: any) => item.id === row.id) || row)
}

const getShipmentFees = (fees: any) => {
  let sum = 0
  if (!isEmpty(fees)) {
    sum = fees.reduce((accumulator: any, object: any) => {
      return accumulator + object?.amount ?? 0
    }, 0)
  }

  return sum
}

const hasNoSubdomain = () => {
  const domain = BASE_DOMAIN?.split(':')[0]
  return document.location.hostname === domain
}

function isUSMilitaries(toCity: string, fromCity: string) {
  return (
    OrdersConfig.US_MILITARIES.includes(toCity) || OrdersConfig.US_MILITARIES.includes(fromCity)
  )
}

const checkUnique = (arr) => {
  const trimmedArr = arr.map((value) => value.toLowerCase().trim())
  return trimmedArr.some((value, index) => trimmedArr.indexOf(value) !== index)
}

const checkRowHaveData = async (file) => {
  let row = 1
  let header: any[] = []

  try {
    const data = await file.arrayBuffer()
    const wb = read(data)
    const ws = wb.Sheets[wb.SheetNames[0]]
    const range = utils.decode_range(ws['!ref'] || '')
    row = range.e.r - range.s.r + 1
    const dataExcel = utils.sheet_to_json(ws, {header: 1, blankrows: false})
    const nonEmptyRows = dataExcel.filter((row: any) => row.length > 0)

    row = nonEmptyRows.length
    header = (nonEmptyRows.length > 0 ? nonEmptyRows[0] : []) as any[]
  } catch (error) {
    // pass
  }

  return {row, header}
}

const convertHeaderFile = (header) => {
  const convertHeader = header.map((col: string) => {
    const colString = String(col)?.trim()
    const removeEndsWith = colString.endsWith('*') ? colString.slice(0, -1) : colString
    const formattedValue = removeEndsWith.toLowerCase().replace(/\s+/g, '_')
    return {
      label: colString,
      value: formattedValue,
    }
  })
  return convertHeader
}

const fileHelper = {
  checkRowHaveData,
  convertHeaderFile,
}

export {
  allowTestOnly,
  checkUnique,
  fileHelper,
  getShipmentFees,
  hasNoSubdomain,
  isUSMilitaries,
  setLoadingActionTable,
}
