import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import {isEmpty} from 'lodash'
import {SET_UP_MODAL_ALL} from '../core/_const'
import ShipmentService from '../../shipments/core/_requests'
import {ActionAllDoneModal} from './ActionAllDoneModal'

type Props = {
  show: boolean
  setUpModal: {
    key: string
    title: string
    process: string
    failed: string
    countCallApi: number
    notice: {
      title: string
      success: string
      failed: string
      showPrintLabels: boolean
    }
  }
  isShipments?: boolean
  isSelectAll?: boolean
  data: any
  handleClose: () => void
}

const ActionAllModal: React.FC<Props> = ({
  setUpModal,
  show,
  data,
  handleClose,
  isSelectAll = false,
  isShipments = false,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [processRow, setProcessRow] = useState<number>(0)
  const [failedRow, setFailedRow] = useState<number>(0)
  const [percentage, setPercentage] = useState<number>(0)
  const [noticeModal, setNoticeModal] = useState<{show: boolean; arrNotice: Array<object>}>({
    show: false,
    arrNotice: [{data: {}, success: {}, error: {}}],
  })

  const arrCallApi = useMemo(() => {
    const arrNew: any = []

    for (let i = 0; i < data.length; i += setUpModal.countCallApi) {
      arrNew.push(data.slice(i, i + setUpModal.countCallApi))
    }
    return arrNew
  }, [data, setUpModal.countCallApi])

  const autoCallApi = useCallback(async () => {
    const results: any = []
    let count = 0
    let close = false

    for (let i = 0; i < arrCallApi.length; i++) {
      if (close) break
      try {
        if (setUpModal.key === SET_UP_MODAL_ALL.CREATE_LABELS.key) {
          await Promise.all(
            arrCallApi[i].map((row) => {
              const _result: any = {}
              return ShipmentService.store({id: row.original.id}, {cancelToken: newCancelToken()})
                .then((response) => {
                  _result.success = response
                  if (!response.label_url) {
                    setFailedRow((prev) => prev + 1)
                  } else {
                    setProcessRow((prev) => prev + 1)
                  }
                })
                .catch((err) => {
                  _result.error = err.response
                  setFailedRow((prev) => prev + 1)
                })
                .finally(() => {
                  if (!isEmpty(_result)) {
                    _result.data = row.original
                    results.push(_result)
                  }
                })
            })
          )
        }
        if (setUpModal.key === SET_UP_MODAL_ALL.REFUND_REQUEST.key) {
          await Promise.all(
            arrCallApi[i].map((row) => {
              const _result: any = {}
              return ShipmentService.refund(row.original.id, {}, {cancelToken: newCancelToken()})
                .then((response) => {
                  _result.success = response
                  if (!response.label_url) {
                    setFailedRow((prev) => prev + 1)
                  } else {
                    setProcessRow((prev) => prev + 1)
                  }
                })
                .catch((err) => {
                  _result.error = err.response
                  setFailedRow((prev) => prev + 1)
                })
                .finally(() => {
                  if (!isEmpty(_result)) {
                    _result.data = row.original
                    results.push(_result)
                  }
                })
            })
          )
        }
        if (setUpModal.key === SET_UP_MODAL_ALL.RETURN_LABELS.key) {
          await Promise.all(
            arrCallApi[i].map((row) => {
              const _result: any = {}
              return ShipmentService.printReturnLabel(row.data_payload, {
                cancelToken: newCancelToken(),
              })
                .then((response) => {
                  _result.success = response
                  if (!response.label_url) {
                    setFailedRow((prev) => prev + 1)
                  } else {
                    setProcessRow((prev) => prev + 1)
                  }
                })
                .catch((err) => {
                  _result.error = err.response
                  setFailedRow((prev) => prev + 1)
                })
                .finally(() => {
                  if (!isEmpty(_result)) {
                    _result.data = row
                    results.push(_result)
                  }
                })
            })
          )
        }
        if (setUpModal.key === SET_UP_MODAL_ALL.PACKING_SLIPS.key) {
          if (isSelectAll) {
            await Promise.all(
              // eslint-disable-next-line no-loop-func
              arrCallApi[i].map((row) => {
                const _result: any = {}
                const data = row.original
                return ShipmentService.storePackingSlip(
                  {order_id: data?.id},
                  {cancelToken: newCancelToken()}
                )
                  .then((res) => {
                    _result.success = res
                    setProcessRow((prev) => prev + 1)
                  })
                  .catch((err: any) => {
                    if (isCancel(err)) {
                      close = true
                    }
                    _result.error = err.response
                    setFailedRow((prev) => prev + 1)
                  })
                  .finally(() => {
                    if (!isEmpty(_result)) {
                      _result.data = row.original
                      results.push(_result)
                    }
                  })
              })
            )
          } else {
            await Promise.all(
              // eslint-disable-next-line no-loop-func
              arrCallApi[i].map((row) => {
                const _result: any = {}
                let payload: any
                if (isShipments) {
                  const dataShipments = row.original
                  payload = {
                    gori_shipment_id: dataShipments.id,
                    order_id: dataShipments.order_id ?? null,
                  }
                } else {
                  const dataOrder = row.original
                  payload = {
                    order_id: dataOrder.id,
                  }
                }

                return ShipmentService.storePackingSlip(payload, {cancelToken: newCancelToken()})
                  .then((res) => {
                    _result.success = res
                    setProcessRow((prev) => prev + 1)
                  })
                  .catch((err: any) => {
                    if (isCancel(err)) {
                      close = true
                    }
                    _result.error = err.response
                    setFailedRow((prev) => prev + 1)
                  })
                  .finally(() => {
                    if (!isEmpty(_result)) {
                      _result.data = row.original
                      results.push(_result)
                    }
                  })
              })
            )
          }
        }
      } catch (error) {
        if (isCancel(error)) return
      }

      count += setUpModal.countCallApi
      setPercentage(Math.round((count / data.length) * 100))
    }
    await setNoticeModal({show: true, arrNotice: results})
  }, [
    setUpModal.key,
    setUpModal.countCallApi,
    isShipments,
    arrCallApi,
    data.length,
    newCancelToken,
    isSelectAll,
    isCancel,
  ])

  useEffect(() => {
    autoCallApi()
  }, [autoCallApi])

  return (
    <>
      {noticeModal.show && (
        <ActionAllDoneModal
          setUpModal={setUpModal.notice}
          show={noticeModal.show}
          handleClose={() => {
            setNoticeModal({show: false, arrNotice: []})
            handleClose()
          }}
          arrNotice={noticeModal.arrNotice}
        />
      )}
      {!noticeModal.show && (
        <Modal
          id='gori_action_all_modal'
          tabIndex={-1}
          aria-hidden='true'
          centered
          dialogClassName='mw-500px h-auto'
          show={show}
          onHide={handleClose}
          backdrop='static'
        >
          <div className='modal-content'>
            <Modal.Header>
              <Modal.Title bsPrefix={'fw-bolder fs-1'}>
                {intl.formatMessage({id: setUpModal.title})}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {intl.formatMessage(
                  {id: setUpModal.process},
                  {process: processRow, total: data.length}
                )}
              </div>
              <div className='text-danger'>
                {failedRow > 0 && intl.formatMessage({id: setUpModal.failed}, {failed: failedRow})}
              </div>
              <div className='mt-5'>
                <div className='progress h-30px'>
                  <div
                    role='progressbar'
                    className='progress-bar bg-image-gori'
                    style={{width: `${percentage}%`}}
                    aria-valuenow={percentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {percentage >= 100 ? 100 : Math.ceil(percentage)}%
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={`btn btn-${percentage >= 100 ? 'success' : 'danger'} d-flex`}
                label={
                  percentage >= 100
                    ? intl.formatMessage({id: 'DONE'})
                    : intl.formatMessage({id: 'STOP'})
                }
                event={handleClose}
              />
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  )
}

export {ActionAllModal}
