import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, convertCurrency} from '../../../helpers'
import {SERVICES} from '../../../constants'

type Props = {
  handleGetRate?: any
  loadingRate?: boolean
  service: any
  isCheapest?: boolean
  cost?: any
}

const CarrierBadge: React.FC<Props> = ({
  handleGetRate,
  loadingRate,
  service,
  isCheapest = false,
  cost,
}) => {
  const intl = useIntl()

  return (
    <div className='d-flex align-items-center align-middle justify-content-center'>
      <div className='d-flex flex-column'>
        {!isEmpty(service) && (
          <div className='symbol symbol-circle symbol-25px overflow-hidden d-flex justify-content-center'>
            <div className='symbol-label'>
              <img
                src={service.visuals?.logo}
                alt='logo'
                className={clsx('w-100', {
                  'rounded-circle':
                    `${service.carrier}_${service.service}` === SERVICES.DHL_EXPRESS,
                })}
              />
            </div>
          </div>
        )}

        <div className='d-flex justify-content-center text-center'>
          <span className='mb-1' style={{fontSize: '12px'}}>
            {service.visuals?.display_name}
          </span>
        </div>
        <div className='d-flex justify-content-center'>
          {isEmpty(cost) && handleGetRate ? (
            <div
              className={'btn-icon text-hover-primary cursor-pointer mb-2'}
              onClick={handleGetRate}
            >
              <KTSVG
                path='/media/gori/orders/refresh.svg'
                className={clsx('svg-icon svg-icon-4')}
                svgClassName={clsx({spin_reverse: loadingRate})}
              />
            </div>
          ) : cost && cost > 0 ? (
            <span className='text-gray-800'>{convertCurrency(cost)}</span>
          ) : (
            <span></span>
          )}
        </div>
        {isCheapest && (
          <div className='d-flex justify-content-center'>
            <span className='text-truncate badge badge-pill badge-light'>
              {intl.formatMessage({id: 'CHEAPEST'})}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export {CarrierBadge}
