import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'
import AuthRequestBase from '../../../api/AuthRequestBase'

const URL_DEPOSITS = '/payments'

const getElavonToken = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_DEPOSITS}/create-elavon-token`, payload, config)
}

const approvalElavon = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_DEPOSITS}/approval-elavon`, payload, config)
}

const getWireTransfers = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL_DEPOSITS}/wire`, config)
}

const wireTransfer = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL_DEPOSITS}/wire`, payload, config)
}

const updateACH = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post(`${URL_DEPOSITS}/ach`, payload, config)
}

const PaymentsService = {
  getElavonToken,
  approvalElavon,
  getWireTransfers,
  wireTransfer,
  updateACH,
}

export default PaymentsService
