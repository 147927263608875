import {AxiosRequestConfig} from 'axios'
import RequestBase from '../../../api/RequestBase'

const URL = '/orders'

const search = async (config?: AxiosRequestConfig | undefined) => {
  return await RequestBase.get(URL, config)
}

const get = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}`, config)
}

const rates = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/rates`, payload, config)
}

const cheapestRate = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/cheapest-rate`, payload, config)
}

const estimateRate = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/estimate-rate`, payload, config)
}

const validateAddress = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/validate-address`, payload, config)
}

const store = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(URL, payload, config)
}

const update = (id: any, payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/${id}`, payload, config)
}

const cancel = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/cancel`, payload, config)
}

const restore = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/restore`, payload, config)
}

const patch = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.patch(`${URL}`, payload, config)
}

const syncOrders = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/sync-orders`, payload, config)
}

const print = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post(`${URL}/print`, payload, config)
}

const holdOrder = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/on-hold`, payload, config)
}

const removeHold = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/remove-hold`, payload, config)
}

const assign = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/assign`, payload, config)
}

const split = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/split`, payload, config)
}

const combine = (payload?: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.put(`${URL}/combine`, payload, config)
}

const latestShipment = (id: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get(`${URL}/${id}/latest-shipment`, config)
}

// BEGIN: Filter order
const getDataSaveFilter = (config?: AxiosRequestConfig | undefined) => {
  return RequestBase.get('/filters?filter_key=order_filter', config)
}

const createDataSaveFilter = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/filters', payload, config)
}

const deleteDataSaveFilter = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return RequestBase.post('/filters/delete', payload, config)
}
// END: Filter order

const OrderService = {
  search,
  get,
  rates,
  cheapestRate,
  estimateRate,
  validateAddress,
  store,
  update,
  cancel,
  restore,
  patch,
  syncOrders,
  print,
  holdOrder,
  removeHold,
  assign,
  split,
  combine,
  latestShipment,
  getDataSaveFilter,
  createDataSaveFilter,
  deleteDataSaveFilter,
}

export default OrderService
