import React, {useEffect, useMemo} from 'react'
import {Link, Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {NewPassword} from './components/NewPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {useIntl} from 'react-intl'
import {ActiveAccount} from './components/ActiveAccount'
import {PendingActivation} from './components/PendingActivation'
import {ActiveUser} from './components/ActiveUser'

const AuthLayout = () => {
  const intl = useIntl()
  const location = useLocation()
  const isLoginPage = useMemo(
    () => location.pathname === '/auth/login' || location.pathname === '/auth',
    [location.pathname]
  )

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundColor: '#E4EFEE',
        backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <Link to={isLoginPage ? '/landing' : 'login'} className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
            className='h-60px'
          />
        </Link>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <Outlet />

        {/* end::Wrapper */}
        {/* end::Content */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <a
              href='https://gori.ai'
              target='_blank'
              rel='noreferrer'
              className='text-muted text-hover-primary px-2'
            >
              {intl.formatMessage({id: 'ABOUT'})}
            </a>

            <Link to='/support' className='text-muted text-hover-primary px-2'>
              {intl.formatMessage({id: 'CONTACT_US'})}
            </Link>
          </div>
        </div>
        {/* end::Footer */}
      </div>
    </div>
  )
}

const AuthPage = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<Login />} />
          <Route path='registration' element={<Registration />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='new-password' element={<NewPassword />} />
          <Route path='active-account' element={<ActiveAccount />} />
          <Route path='pending-activation' element={<PendingActivation />} />
          <Route path='active-user' element={<ActiveUser />} />
          <Route index element={<Login />} />
        </Route>
      </Routes>
    </>
  )
}

export {AuthPage}
