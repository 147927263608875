import {useFormik} from 'formik'
import {cloneDeep, isEmpty, startCase} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {Container, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ShowAddress} from '../../../../_gori/components'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button, SelectFormik, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import SettingsService from '../../settings/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const PresetModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup} = UseYupValidate()
  const {statesGlobal, getPresets} = useStatesGlobal()
  const presets = statesGlobal.presets
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean}>({
    update: false,
  })

  const initValidateSchema = useMemo(() => {
    return {
      shipping_preset_id: stringYup(50, 'PRESET_NAME'),
    }
  }, [stringYup])

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  useEffect(() => {
    getPresets()

    return () => {}
  }, [getPresets])

  const optionsPreset = useMemo(() => {
    if (isEmpty(presets)) return []
    return (
      presets &&
      Object.entries(presets).map(([key, value]: [any, any]) => ({
        value: value?.id,
        label: value?.name,
      }))
    )
  }, [presets])

  const formik = useFormik({
    initialValues: {
      order_ids: [],
      shipping_preset_id: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const orderItems = orders.map((item: any) => {
        return item.original.id
      })

      let payload = {
        shipping_preset_id: values.shipping_preset_id,
        order_ids: orderItems,
      }

      try {
        setLoading((prev) => ({...prev, update: true}))

        const {message} = await SettingsService.applyShippingPreset(payload, {
          cancelToken: newCancelToken(),
        })
        if (message) {
          toast.success(intl.formatMessage({id: message}))
          handleCloseModal()
          reloadTable()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  const selectedValue = useMemo(() => {
    if (!formik.values.shipping_preset_id) return null
    const _selectedValue = cloneDeep(presets)?.filter(
      (item) => item.id === formik.values.shipping_preset_id
    )
    return _selectedValue?.[0]?.shipping_options
  }, [presets, formik.values.shipping_preset_id])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_preset_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'APPLY_PRESET'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex justify-content-between align-items-center'>
                <span className='fs-2 fw-bolder required'>
                  {intl.formatMessage({id: 'PRESET_NAME'})}
                </span>
                <span className='fw-bold text-primary'>
                  {intl.formatMessage(
                    {id: 'THIS_WILL_APPLY_TO_INPUT_SELECTED_SHIPMENTS'},
                    {
                      input: orders.map((item: any) => {
                        return item.original.id
                      }).length,
                    }
                  )}
                </span>
              </div>
              <div className='d-flex align-items-center my-6'>
                <SelectFormik
                  className='flex-fill'
                  placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                  options={optionsPreset}
                  formik={formik}
                  name='shipping_preset_id'
                  required
                />
              </div>
              {formik.values.shipping_preset_id && (
                <div>
                  <div className='d-flex my-2'>
                    <div className='col-3'>{intl.formatMessage({id: 'SHIP_FROM'})}:</div>
                    {!isEmpty(selectedValue?.from) && (
                      <ShowAddress data={selectedValue?.from} name='from' />
                    )}
                  </div>
                  <div className='d-flex my-2'>
                    <div className='col-3'>{intl.formatMessage({id: 'SHIP_TO'})}:</div>
                    {!isEmpty(selectedValue?.to) && (
                      <ShowAddress data={selectedValue?.to} name='to' />
                    )}
                  </div>
                  <div className='d-flex my-2'>
                    <div className='col-3'>{intl.formatMessage({id: 'SERVICE'})}:</div>
                    {!isEmpty(selectedValue?.service) && (
                      <div>
                        <span className='text-muted d-block fw-bold'>
                          {selectedValue?.service
                            ? `${selectedValue?.service?.split('_')[0]?.toUpperCase()} ${startCase(
                                selectedValue.service?.split('_')?.splice(1).join(' ')
                              )}`
                            : ''}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='d-flex my-2'>
                    <div className='col-3'>{intl.formatMessage({id: 'PACKAGE_INFO'})}: </div>
                    {!isEmpty(selectedValue?.parcels?.[0]) && (
                      <div>
                        {selectedValue?.parcels?.[0]?.length &&
                          selectedValue?.parcels?.[0]?.width &&
                          selectedValue?.parcels?.[0]?.height && (
                            <div className='text-muted'>
                              {intl.formatMessage({id: 'LENGTH'})}:{' '}
                              {selectedValue?.parcels?.[0]?.length || ''}{' '}
                              {intl.formatMessage({id: 'WIDTH'})}:{' '}
                              {selectedValue?.parcels?.[0]?.width || ''}{' '}
                              {intl.formatMessage({id: 'HEIGHT'})}:{' '}
                              {selectedValue?.parcels?.[0]?.height || ''} (
                              {selectedValue?.parcels?.[0]?.dimension_unit})
                            </div>
                          )}
                        {selectedValue?.parcels?.[0]?.weight && (
                          <div className='text-muted'>
                            {intl.formatMessage({id: 'WEIGHT'})}:{' '}
                            {selectedValue?.parcels?.[0]?.weight || ''} (
                            {selectedValue?.parcels?.[0]?.weight_unit})
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={loading.update}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'UPDATE'})}
                loadingText={intl.formatMessage({id: 'UPDATE'})}
                event={formik.handleSubmit}
                loading={loading.update}
                disabled={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {PresetModal}
