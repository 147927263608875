import React, {useContext, useEffect, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {MeterModel} from '../../../app/modules/users/core/_models'
import UserService from '../../../app/modules/users/core/_requests'
import {round} from '../FormatHelpers'

export interface MeterContextModel {
  isMeterChange?: Boolean
  meter: MeterModel
  setMeterChange: (_isMeterChange: boolean) => void
}

const MeterContext = React.createContext<MeterContextModel>({
  meter: {balance: 0, recharge_amount: 0, recharge_threshold: 0, is_enabled: false, name: ''},
  setMeterChange: (_isMeterChange: boolean) => {},
})

const MeterProvider: React.FC = ({children}) => {
  const [isMeterChange, setMeterChange] = useState(true)
  const [meter, setMeter] = useState<MeterModel>({
    balance: 0,
    recharge_amount: 0,
    recharge_threshold: 0,
    is_enabled: false,
    name: '',
  })
  const {currentUser, loadingSwitch} = useAuth()

  const value: MeterContextModel = {
    isMeterChange,
    meter,
    setMeterChange,
  }

  const loadMeter = async () => {
    return await UserService.getMeter()
  }
  useEffect(() => {
    if (loadingSwitch) return
    if (isMeterChange && currentUser) {
      loadMeter()
        .then((response) => {
          response.balance = round(Number(response.balance))
          setMeter(response)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setMeterChange(false)
        })
    }
  }, [currentUser, isMeterChange, loadingSwitch])

  return <MeterContext.Provider value={value}>{children}</MeterContext.Provider>
}

function useMeter() {
  return useContext(MeterContext)
}

export {MeterProvider, useMeter}
