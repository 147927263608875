import clsx from 'clsx'
import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {useAuth} from '../../../../app/modules/auth'
import {SettingsMenu} from '../../../../app/modules/settings'
import {KTSVG, checkIsActive} from '../../../helpers'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {AsideUserMenu} from '../../../partials'
import {useAsideProvider} from '../../../providers/AsideProvider'

type Props = {
  item: any
  key: any
}

const MenuItemShow: React.FC<Props> = ({item, key}) => {
  const {activeFooter} = useAsideProvider()
  const {isMobileTablet, isWindows} = useWindowDimensions()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const {hasShow, hasNavigate, to, icon, title, additionalClasses} = item
  const isActive = activeFooter === to || (!activeFooter && checkIsActive(pathname, to))
  if (!hasShow) {
    return null
  }

  const submenu = () => {
    switch (to) {
      case '/accounts':
        return (
          <div
            id='aside-footer-accounts'
            className={clsx(
              'ms-12 ms-xxl-8 menu menu-sub menu-sub-dropdown menu-column menu-rounded w-auto bg-light p-4'
            )}
            data-kt-menu='true'
          >
            <AsideUserMenu />
          </div>
        )
      case '/settings':
        return isMobileTablet ? (
          <div
            id='aside-footer-settings'
            className={clsx(
              'ms-6 ms-sm-12 ms-xxl-8 menu menu-sub menu-sub-dropdown menu-column menu-rounded w-auto mw-50 bg-light p-4'
            )}
            data-kt-menu='true'
          >
            <SettingsMenu />
          </div>
        ) : (
          <div
            id='aside-footer-settings'
            className={clsx('aside--sub__settings')}
            data-kt-menu='true'
          >
            <div className='mt-xxl-30'></div>
            <div className='mt-xxl-12'></div>
            <div className='aside--sub__center'>
              <SettingsMenu />
            </div>
          </div>
        )
    }
  }

  return hasNavigate ? (
    <div
      key={key}
      className={clsx('aside--main__footer__item', {
        active: isActive,
      })}
      onClick={() => {
        navigate(to)
      }}
    >
      <div className='d-flex align-items-center'>
        <KTSVG
          path={icon}
          small={false}
          className={clsx('aside--main__footer__item__icon', additionalClasses)}
          svgClassName={(isMobileTablet && 'mh-40px') || (isWindows && 'mh-50px') || ''}
        />
        <span
          className={clsx('text-white flex-fill d-flex d-xxl-none fs-5 fs-xxl-6', {
            'fw-bolder': !isActive,
            'fw-boldest': isActive,
          })}
        >
          {title}
        </span>
      </div>
    </div>
  ) : (
    <div
      key={key}
      className={clsx('aside--main__footer__item', {
        active: isActive,
      })}
    >
      <div
        className={clsx('d-flex align-items-center', additionalClasses)}
        data-kt-menu-trigger='click'
        data-kt-menu-overflow='false'
        data-kt-menu-placement='right-end'
        data-kt-menu-permanent='true'
        data-kt-menu-static='true'
      >
        <KTSVG
          path={icon}
          small={false}
          svgClassName={(isMobileTablet && 'mh-40px') || (isWindows && 'mh-50px') || ''}
          className={clsx('aside--main__footer__item__icon', additionalClasses)}
        />
        <span
          className={clsx('text-white flex-fill d-flex d-xxl-none fs-5 fs-xxl-6', {
            'fw-bolder': !isActive,
            'fw-boldest': isActive,
          })}
        >
          {title}
        </span>
      </div>

      {submenu()}
    </div>
  )
}

const Menu = ({configMenu}) => {
  const {setActiveFooter} = useAsideProvider()
  const handleClickAsideFooter = (menuItem) => {
    setActiveFooter(menuItem.to)
  }

  return (
    <div className='py-4'>
      {configMenu.map((menuItem, idx) => (
        <div
          className='px-4 px-sm-9 px-xxl-4'
          onClick={() => handleClickAsideFooter(menuItem)}
          id='kt_accordion_menu_main'
        >
          <MenuItemShow key={idx} item={menuItem} />
        </div>
      ))}
    </div>
  )
}

const AsideFooterLayout: React.FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const configMenu = [
    {
      // Opens Menu
      hasShow: currentUser,
      to: '/settings',
      icon: '/media/gori/aside/settings.svg',
      title: intl.formatMessage({id: 'SETTINGS'}),
      additionalClasses: 'mobile-only settings-menu-trigger',
    },
    {
      // Navigates to page
      hasShow: currentUser,
      hasNavigate: true,
      to: '/settings/profile',
      icon: '/media/gori/aside/settings.svg',
      title: '',
      additionalClasses: 'desktop-only',
    },
    {
      hasShow: currentUser,
      to: '/accounts',
      icon: '/media/gori/aside/account.svg',
      title: intl.formatMessage({id: 'ACCOUNT'}),
    },
    {
      hasShow: true,
      hasNavigate: true,
      to: '/support',
      icon: '/media/gori/aside/support.svg',
      title: intl.formatMessage({id: 'SUPPORT'}),
    },
  ]

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <Menu configMenu={configMenu} />
    </CSSTransition>
  )
}

export {AsideFooterLayout}
