import clsx from 'clsx'
import {pick, size} from 'lodash'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {OPTION_COUNTRIES, SERVICES} from '../../../../../_gori/constants'
import {KTSVG} from '../../../../../_gori/helpers'
import {InputTextFormik, SelectFormik} from '../../../../../_gori/partials/widgets'
import {OPTIONS_CONTENT_TYPE, OPTIONS_CONTENT_TYPE_UPS, OrdersConfig} from '../../core/_const'

type Props = {
  disabled?: any
  formik: any
  handleAddNewItem?: any
  handleDeleteItem?: any
  showHide?: any
  setShowHide?: any
  isShippedPage?: any
}

const CustomsInternational: React.FC<Props> = ({
  formik,
  disabled,
  handleAddNewItem,
  handleDeleteItem,
  showHide,
  setShowHide,
  isShippedPage = false,
}) => {
  const intl = useIntl()

  const contentTypeOptions = useMemo(() => {
    if (formik.values.service === SERVICES.UPS_MI) {
      if (
        !OPTIONS_CONTENT_TYPE_UPS.some(
          (option) => option.value === formik.values.international_option.content
        )
      ) {
        formik.setFieldValue('international_option.content', null)
      }
      return OPTIONS_CONTENT_TYPE_UPS
    }

    return OPTIONS_CONTENT_TYPE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.service])

  return (
    <div
      className={clsx('bg-white rounded-2 border border-primary p-6', {
        'bg-light cursor-no-drop': disabled,
      })}
    >
      <div className='d-flex justify-content-between align-items-center'>
        <h2>{intl.formatMessage({id: 'CUSTOM'})}</h2>
        {isShippedPage && (
          <span
            className='cursor-pointer text-hover-primary p-2 bg-hover-light-primary rounded-circle'
            onClick={() => setShowHide((prev) => ({...prev, custom: !prev.custom}))}
          >
            <KTSVG path='/media/gori/arrows/arrow_down.svg' className='svg-icon-2' />
          </span>
        )}
      </div>
      {((showHide.custom && isShippedPage) || !isShippedPage) && (
        <div>
          <hr className='bg-gray-600' />
          <div className='row'>
            {Object.entries(pick(OrdersConfig.CUSTOMS, ['CONTENT', 'SIGNER', 'DESCRIPTION'])).map(
              ([key, value]: [any, any], index) => {
                switch (key) {
                  case 'CONTENT':
                    return (
                      <div className='col-md-6 my-2' key={index}>
                        <div className='d-flex'>
                          <SelectFormik
                            className={clsx('col-9')}
                            labelClassName='col-3 col-form-label text-muted'
                            label={intl.formatMessage({id: 'CONTENT'})}
                            formik={formik}
                            options={contentTypeOptions}
                            required
                            name={'international_option.content'}
                            hasUseIntl={true}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    )

                  default:
                    return (
                      <div className='col-md-6 my-2' key={index}>
                        <div className='d-flex'>
                          <InputTextFormik
                            className={clsx('col-9')}
                            labelClassName='col-3 col-form-label text-muted'
                            label={intl.formatMessage({id: value?.label.toUpperCase()})}
                            formik={formik}
                            name={`international_option.${value?.value}`}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    )
                }
              }
            )}
          </div>
          {formik?.values?.custom_items &&
            Object.entries(formik.values?.custom_items).map(([keyItem, valueItem], index) => {
              return (
                <div className='mt-5 pt-5 border-top border-secondary' key={index}>
                  <div className='d-flex justify-content-between'>
                    <h5 className='text-muted'>{`${intl.formatMessage({
                      id: 'DECLARATION',
                    })}  ${index + 1}`}</h5>
                    <div className='d-flex justify-content-between'>
                      {size(formik.values.custom_items) > 1 && (
                        <div
                          className={clsx(
                            'd-flex align-items-center text-danger fw-bolder cursor-pointer',
                            {
                              'cursor-no-drop': disabled,
                            }
                          )}
                          onClick={() => !disabled && handleDeleteItem('custom_items', keyItem)}
                        >
                          <span className='fs-2 me-1'>-</span>
                          {intl.formatMessage({id: 'DELETE'})}
                        </div>
                      )}
                      {size(formik.values.custom_items) - 1 === index && (
                        <div
                          className={clsx(
                            'd-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer ms-4',
                            {
                              'cursor-no-drop': disabled,
                            }
                          )}
                          onClick={() => !disabled && handleAddNewItem('custom_items')}
                        >
                          <span className='fs-2 me-1'>+</span>
                          {intl.formatMessage({id: 'ADD_ITEM'})}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex flex-wrap flex-fill'>
                    {Object.entries(OrdersConfig.CUSTOMS.ITEMS).map(
                      ([_keyItem, _valueItem], index) => {
                        switch (_valueItem.value) {
                          case 'country':
                            return (
                              <div className='col-md-3' key={index}>
                                <SelectFormik
                                  className={clsx('me-4')}
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={intl.formatMessage({id: _valueItem.label})}
                                  options={OPTION_COUNTRIES || []}
                                  formik={formik}
                                  name={`custom_items.${keyItem}.${_valueItem.value}`}
                                  disabled={disabled}
                                />
                              </div>
                            )

                          default:
                            let textExpand
                            const isFieldDescription =
                              _valueItem.value === OrdersConfig.CUSTOMS.ITEMS.DESCRIPTION.value
                            const isFieldQuantity =
                              _valueItem.value === OrdersConfig.CUSTOMS.ITEMS.QUANTITY.value

                            switch (_valueItem.value) {
                              case OrdersConfig.CUSTOMS.ITEMS.VALUE.value:
                                textExpand = `($)`
                                break

                              case OrdersConfig.CUSTOMS.ITEMS.WEIGHT.value:
                                textExpand = `(OZ)`
                                break

                              default:
                                textExpand = ''
                                break
                            }

                            return (
                              <div
                                className={clsx('col-md-3', {
                                  'col-md-6': isFieldDescription,
                                })}
                                key={index}
                              >
                                <InputTextFormik
                                  type={OrdersConfig.CUSTOMS.ITEMS[_keyItem].type}
                                  className={clsx('me-4')}
                                  labelClassName='col-form-label text-nowrap text-muted'
                                  label={`${intl.formatMessage({
                                    id: _valueItem.label,
                                  })} ${textExpand}`}
                                  formik={formik}
                                  name={`custom_items.${keyItem}.${_valueItem.value}`}
                                  isInteger={isFieldQuantity}
                                  required={OrdersConfig.CUSTOMS.ITEMS[_keyItem].required}
                                  disabled={disabled}
                                />
                              </div>
                            )
                        }
                      }
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export {CustomsInternational}
