import {AxiosRequestConfig} from 'axios'
import AuthRequestBase from '../../../api/AuthRequestBase'

const URL_MNDA = '/agreements'

const verify = (password?: string, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.get(`${URL_MNDA}/verify/${password}`, config)
}

const clientSignature = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.post(`${URL_MNDA}`, payload, config)
}

const getClientSignature = (company_id: string, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.get(`${URL_MNDA}/${company_id}`, config)
}

const salesSignature = (payload: any, config?: AxiosRequestConfig | undefined) => {
  return AuthRequestBase.put(`${URL_MNDA}`, payload, config)
}

const MNDAService = {
  verify,
  clientSignature,
  salesSignature,
  getClientSignature,
}

export default MNDAService
