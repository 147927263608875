import {FC} from 'react'
import {Route, Routes} from 'react-router-dom'
import {PageDisabledProvider} from '../../../_gori/helpers'
import {DisableSidebar} from '../../../_gori/layout/core'
import {GlobalShopifyVerifyPage} from './GlobalShopifyVerifyPage'
import {TikTokVerifyPage} from './TikTokVerifyPage'

const GlobalRoute: FC = () => {
  return (
    <Routes>
      <Route path='shopify/verify/*' element={<GlobalShopifyVerifyPage />} />
      <Route path='tiktok/verify/*' element={<TikTokVerifyPage />} />
    </Routes>
  )
}

const GlobalWrapper: FC = () => {
  return (
    <DisableSidebar>
      <PageDisabledProvider>
        <GlobalRoute />
      </PageDisabledProvider>
    </DisableSidebar>
  )
}

export default GlobalWrapper
