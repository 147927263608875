import React from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'

import Card from '../common/Card'

const ManualOrder: React.FC = () => {
  const intl = useIntl()

  return (
    <Card
      title={intl.formatMessage({id: 'EXPLORING_PRICING_OPTIONS_FOR_ESTABLISHING_A_NEW_LABEL'})}
      learnMoreUrl={
        'https://gori-ai.notion.site/Learn-more-about-Create-Manual-Label-43f7c1084e1d4ae2aadb8af79716bc97'
      }
    >
      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/gori/onboard/label/v2/manual_order.png')}
        className='w-100'
      />
    </Card>
  )
}

export {ManualOrder}
