import React, {FC} from 'react'
import clsx from 'clsx'
import {getIn} from 'formik'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  rows?: number
  name: string
  formik: any
  required?: boolean
  append?: string
  bgSolid?: boolean
  disabled?: boolean
  checkFormik?: boolean
}

const InputTextArea: FC<Props> = ({
  className,
  labelClassName = null,
  label = null,
  rows = 5,
  formik,
  name,
  append,
  required = false,
  bgSolid = false,
  disabled = false,
  checkFormik = true,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)

  return (
    <>
      {label || labelClassName ? (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={className}>
        <div className='input-group'>
          <textarea
            rows={rows}
            autoComplete='off'
            className={clsx(
              `form-control`,
              {'form-control-solid': bgSolid},
              {'is-invalid': formikTouched && formikErrors && checkFormik},
              {'is-valid': formikTouched && !formikErrors && fieldProps.value && checkFormik}
            )}
            disabled={disabled}
            {...fieldProps}
          />
          {append ? (
            <div className='input-group-append'>
              <span className='input-group-text'>{append}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {InputTextArea}
